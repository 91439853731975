import styled from "styled-components";
import Layout from "antd/lib/layout";
import { Content } from "antd/lib/layout/layout";
import { device } from "shared/util/mediaQueries";

export const ResponsiveLayout = styled(Layout)`
  flex-direction: column;
  flex-flow: wrap;
  background: none;
  @media ${device.laptopM} {
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
  }
`;

export const ResponsiveContent = styled(Content)`
  height: 100%;
  width: 100%;
  @media ${device.laptopM} {
    width: auto;
  }
`;

export const ResponsiveSidebar = styled.div`
  padding: 1rem;
  color: #696969;
  background-color: #fff;
  width: 100%;
  @media ${device.laptopM} {
    overflow-y: auto;
    width: 440px;
  }
`;
