import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ObjectManagerFeature, YMapsApi } from "react-yandex-maps";
import { useDispatch, useSelector } from "react-redux";
import centroid from "@turf/centroid";
import { useSearchParams, useNavigate } from "react-router-dom";

import { StatisticDataType } from "store/statistics/statistics.reducer";
import { selectRegionsMap } from "store/statistics/statistics.selectors";
import { loading } from "store/app/app.reducer";
import { useLegend } from "hooks/legend.hooks";
import { useRegion } from "hooks/region.hooks";

import { MapWrapper } from "./Map.styled";
import MapHeader from "../MapHeader/MapHeader";
import MapDrawer from "./MapDrawer";
import MapFooter from "../MapFooter/MapFooter";
import polygonExceptions from "../../data/polygonExceptions";

const defaultState = {
  zoom: 3,
  center: [62.0, 105.0],
};

const Map: React.FC = () => {
  const region = useRegion();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { getColor } = useLegend();
  const [features, setFeatures] = useState<ObjectManagerFeature[]>([]);
  const [state, setState] = useState(defaultState);

  const regions = useSelector(selectRegionsMap);

  /**
   * Формируем объекты на карте при загрузки
   */
  const handleLoadMap = useCallback(
    async (ymaps: YMapsApi) => {
      dispatch(loading("Загрузка объектов карты"));
      let areas;
      const lsAreas = localStorage.getItem("bmk-map-polygons");
      areas = lsAreas ? JSON.parse(lsAreas) : [];

      if (areas.length > 0) {
        setFeatures(areas);
      } else {
        // Загружаем границы регионов России
        areas = await ymaps.borders.load("RU", {
          lang: "ru",
          quality: 2,
        });

        // Проходимся по всем границам и назначаем ID и доп.данные
        const areaFeatures = areas.features.map((feature: any) => {
          const { iso3166 } = feature.properties;
          feature.id = iso3166;

          feature.options = {
            fillOpacity: 0.1,
            strokeColor: "#000",
            iconColor: "#000",
            strokeOpacity: 0.2,
          };
          return feature;
        });

        setFeatures(areaFeatures as ObjectManagerFeature[]);
        localStorage.setItem("bmk-map-polygons", JSON.stringify(areaFeatures));
      }
      dispatch(loading(false));
    },
    [dispatch]
  );

  const extendedFeatures = useMemo(() => {
    // Показываем полигоны которые входят в статистику
    const polygons = features.filter((feature) => !!regions[feature.id]);

    let points = [];

    // Формируем точки на карте
    points = polygons
      .filter((item) => (region ? item.id === region.r_code : true))
      .map((feature) => {
        // Получаем координаты центральной точки полигона
        const point = centroid(feature as any) as ObjectManagerFeature;

        // Корректируем координаты кастомных точек
        const exceptionCoordinate = polygonExceptions[feature.id];
        if (exceptionCoordinate) {
          point.geometry.coordinates = exceptionCoordinate;
        }

        // Задаем айдишник
        point.id = `#${feature.id}`;

        // Получаем статистику региона по индексу
        const statistics = regions[feature.id] as StatisticDataType;

        // Задаем подпись для значка
        point.properties = {
          ...point.properties,
          iconCaption: statistics.result_graduates,
        };

        // Задаем цвет значка
        const demandColor = getColor(statistics.result_graduates);
        point.options = {
          iconColor: demandColor,
        };

        return point as ObjectManagerFeature;
      });

    return points;
  }, [features, getColor, region, regions]);

  /**
   * Обрабатываем клик по карте
   * @param e
   */
  const handleClickObject = (iso3166: any) => {
    //let iso3166: string = e.get("objectId");
    if (iso3166.startsWith("#")) {
      iso3166 = iso3166.slice(1);
      navigate(`/${iso3166}?${search.toString()}`);
    }
  };

  const handleBoundsChange = (e: any) => {
    setState(() => ({ zoom: e.get("newZoom"), center: e.get("newCenter") }));
  };

  useEffect(() => {
    if (region) {
      const feature = features.find((item) => item.id === region.r_code);
      if (feature) {
        const point = centroid(feature as any) as ObjectManagerFeature;
        const center = point.geometry.coordinates;
        setState(() => ({ zoom: 4, center }));
      }
    } else {
      setState(() => defaultState);
    }
  }, [features, region]);

  return (
    <MapWrapper>
      <MapHeader />
      <MapDrawer
        zoom={state.zoom}
        center={state.center}
        onLoad={handleLoadMap}
        onClick={handleClickObject}
        features={extendedFeatures}
        polygons={features}
        onBoundsChange={handleBoundsChange}
      />
      <MapFooter region={region?.res_code} />
    </MapWrapper>
  );
};

export default Map;
