import Menu from "antd/lib/menu";

const isTest =
  window.location.hostname.startsWith("test.") || process.env.NODE_ENV === "development";

function ServicesDropdown() {
  return (
    <Menu style={{ maxWidth: 200 }}>
      <Menu.Item key="vacancy">
        <a
          rel="noopener noreferrer"
          href={isTest ? "https://test.bmk-map.2035.university" : "https://bmk-map.2035.university"}
        >
          Вакансии
        </a>
      </Menu.Item>
      <Menu.Item key="junior">
        <a
          rel="noopener noreferrer"
          href={
            isTest
              ? "https://test.junior.bmk-map.2035.university"
              : "https://junior.bmk-map.2035.university"
          }
        >
          Кадры
        </a>
      </Menu.Item>
      <Menu.Item key="trends">
        <a
          rel="noopener noreferrer"
          href={
            isTest
              ? "https://test.trends.bmk-map.2035.university"
              : "https://trends.bmk-map.2035.university"
          }
        >
          Тренды
        </a>
      </Menu.Item>
       <Menu.Item key="code">
        <a
          rel="noopener noreferrer"
          href={
            isTest
              ? "https://test.code.bmk-map.2035.university"
              : "https://code.bmk-map.2035.university"
          }
        >
          Код
        </a>
      </Menu.Item>
    </Menu>
  );
}

export default ServicesDropdown;
