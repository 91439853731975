import styled from "styled-components";
import Select from "antd/lib/select";
import { useFilters } from "shared/hooks/filters.hooks";
import { filterKey } from "util/filterKeys";

const StyledSelect = styled(Select)`
  min-width: 100px;
  max-width: 100%;
`;

function SelectYear() {
  const [filter, setFilter] = useFilters();
  const options = [
    { label: "2020", value: "2020" },
    { label: "2021", value: "2021" },
  ];

  const handleChange = (value: unknown) => {
    setFilter({ [filterKey.year]: value as string });
  };

  return (
    <StyledSelect
      allowClear
      placeholder="Выберите год"
      options={options}
      value={filter.year}
      onChange={handleChange}
    />
  );
}

export default SelectYear;
